<template>
 <div>
   <table class="table table-striped">
     <thead>
     <tr>
       <th>Username</th>
       <th>Full Name</th>
       <th>Role</th>
       <th>Email</th>
       <th>Phone</th>
     </tr>
     </thead>
     <tbody>
     <tr v-for="u in active_users" :key="u.username">
       <td>{{u.username}}</td>
       <td>{{u.full_name}}</td>
       <td>{{u.title}}</td>
       <td>{{u.email}}</td>
       <td>{{u.phone}}</td>
     </tr>
     </tbody>
   </table>
 </div>
</template>

<script>
export default {
  name: "Phonebook",
  props: [
      'user',
      'users',
      'loggedIn',
  ],
  computed: {
    active_users() {
      let au = [];
      for (let i=0; i<this.users.length; i++) {
        if (this.users[i].is_active) {
          au.push(this.users[i]);
        }
      }
      return au;
    },
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
    this.$emit('sync-users');
  },
}
</script>

<style scoped>

</style>